import React from "react";
import { TableRow, TableCell } from "@mui/material";
import ElipsisCell from "../Table/Cells/ElipsisCell";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { dateFormat } from "../../utils/methods";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import { ADMIN, SUPER_ADMIN } from "../../utils/appConstants";

const Row = ({
  item,
  headCells,
  handleEditIconClick,
  handleResendIconClick,
  handleDeleteIconClick,
  loggedInUser,
}) => {
  return (
    <TableRow className="table-body-row">
      {headCells.map((headCell) => {
        if (
          headCell.id === "created_date" ||
          headCell.id === "last_activity_time"
        ) {
          const value = _.get(item, headCell.accessor);
          return (
            <ElipsisCell
              key={headCell.id}
              width={headCell.width}
              align={headCell.align}
              text={value ? dateFormat(new Date(value).toISOString()) : ""}
            />
          );
        }

        if (headCell.id === "action") {
          return (
            <TableCell
              key={headCell.id}
              width={headCell.width}
              align={headCell.align}
            >
              <EditIcon
                key="edit-icon"
                className="table-edit-icon"
                onClick={() => handleEditIconClick(item)}
              />
              {loggedInUser?.role_id?.role_name === SUPER_ADMIN && (
                <DeleteIcon
                  key="delete-icon"
                  className="table-remove-icon"
                  onClick={() => handleDeleteIconClick(item)} // Delete icon click handler
                />
              )}
              {(loggedInUser?.role_id?.role_name === SUPER_ADMIN ||
                loggedInUser?.role_id?.role_name === ADMIN) && (
                  <SendIcon
                    key="delete-icon"
                    className="table-sent-icon"
                    onClick={() => {
                      handleResendIconClick(item);
                    }}
                  />
                )}


            </TableCell>
          );
        }

        return (
          <ElipsisCell
            key={headCell.id}
            width={headCell.width}
            align={headCell.align}
            text={_.get(item, headCell.accessor)}
          />
        );
      })}
    </TableRow>
  );
};

export default Row;
