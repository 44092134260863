import http from "../httpConfig";

const getLoggedInUser = (token) => {
  return http.get(`/users/session`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getLastActivityTime = (payload) => {
  return http.get(`/users/last_activity_time`, {
    headers: {
      Authorization: `Bearer ${payload.token}`,
    },
  });
};

const getApiKey = (token) => {
  return http.get(`/users/getApiKey`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getUsers = ({ token, domainId, order, orderBy, page, perPage }) => {
  return http.get(
    `/users?domainId=${domainId}&order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const inviteUser = (token, payload) => {
  return http.post("/users", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const resendInviteToUser = (token, payload) => {
  return http.post("/users/resend-invite", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteUser = (token, userId) => {
  return http.delete(`/users/delete/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const editUser = (token, payload) => {
  return http.patch("/users", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const verifyUserDomain = ({ token, email }) => {
  return http.post(
    `/users/verify-domain`,
    { email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const UserService = {
  getUsers,
  getApiKey,
  getLastActivityTime,
  inviteUser,
  editUser,
  getLoggedInUser,
  verifyUserDomain,
  resendInviteToUser,
  deleteUser,
};

export default UserService;
