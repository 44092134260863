import React, { useMemo, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import moment from "moment";
import Dropdown from "../components/dropdown";
import SwaggerV3Json from "../swagger_v3.json";
import SwaggerV2Json from "../swagger_v2.json";
import SwaggerV4Json from "../swagger_v4.json";
import Logo from "../images/logo.png";

function Swagger({ loggedInUser, actAsDomainIdMemoised, user }) {
  const [versionValue, setVersionValue] = useState({
    value: "V3",
    label: "V3",
  });

  const handleVersionChange = (versionValueObj) => {
    setVersionValue(versionValueObj);
  };

  const modifiedSwaggerJson = useMemo(() => {
    let SwaggerJson = SwaggerV2Json;

    switch (versionValue.value) {
      case "V2": {
        SwaggerJson = SwaggerV2Json;
        break;
      }
      case "V3": {
        SwaggerJson = SwaggerV3Json;
        break;
      }
      case "V4": {
        SwaggerJson = SwaggerV4Json;
        break;
      }
      default: {
        SwaggerJson = SwaggerV3Json;
      }
    }

    if (SwaggerJson) {
      return {
        ...SwaggerJson,
        components: {
          ...SwaggerJson.components,
          schemas: {
            ...SwaggerJson.components.schemas,
            updateOrderCdo: {
              ...SwaggerJson.components.schemas.updateOrderCdo,
              example: {
                ...SwaggerJson.components.schemas.updateOrderCdo.example,
                shipment_date: moment(new Date()).add(8, "h").utc(),
                order_date: moment(new Date()).utc(),
                metered_load: {
                  ...SwaggerJson.components.schemas.updateOrderCdo.example
                    .metered_load,
                  effective_date: moment(new Date()).add(4, "h").utc(),
                  expiration_date: moment(new Date()).add(2, "d").utc(),
                },
              },
            },
            createOrderCdo: {
              ...SwaggerJson.components.schemas.createOrderCdo,
              example: {
                ...SwaggerJson.components.schemas.createOrderCdo.example,
                shipment_date: moment(new Date()).add(8, "h").utc(),
                order_date: moment(new Date()).utc(),
                metered_load: {
                  ...SwaggerJson.components.schemas.createOrderCdo.example
                    .metered_load,
                  effective_date: moment(new Date()).add(4, "h").utc(),
                  expiration_date: moment(new Date()).add(2, "d").utc(),
                },
              },
            },
            getOrdersCdo: {
              ...SwaggerJson.components.schemas.getOrdersCdo,
              example: {
                ...SwaggerJson.components.schemas.getOrdersCdo.example,
                filters: {
                  ...SwaggerJson.components.schemas.getOrdersCdo.example
                    .filters,
                  shipment_date: moment(new Date()).add(8, "h").utc(),
                  order_date: moment(new Date()).utc(),
                  metered_load: {
                    ...SwaggerJson.components.schemas.getOrdersCdo.example
                      .filters.metered_load,
                    effective_date: moment(new Date()).add(4, "h").utc(),
                    expiration_date: moment(new Date()).add(2, "d").utc(),
                  },
                },
              },
            },
          },
        },
      };
    } else {
      return {};
    }
    // }, [])
  }, [JSON.stringify(versionValue)]);

  return (
    <div>
      <div className="swagger-dropdown">
        <div className="swagger-version-main">
          <label className="swagger-version-label">Version</label>
          <div className="swagger-dropdown-version">
            <Dropdown
              options={[
                {
                  value: "V2",
                  label: "V2",
                },
                {
                  value: "V3",
                  label: "V3",
                },
                {
                  value: "V4",
                  label: "V4",
                },
              ]}
              onChange={(newValue) => handleVersionChange(newValue)}
              value={versionValue}
            />
          </div>
        </div>
        <div>
          <img src={Logo} />
        </div>
      </div>
      <SwaggerUI
        oauth2RedirectUrl="https://develop.d2wmxeva59r2o6.amplifyapp.com/redirect"
        spec={modifiedSwaggerJson}
        defaultModelsExpandDepth={-1}
        requestInterceptor={(req) => {
          if (
            req.url.includes("/orders/search") &&
            !actAsDomainIdMemoised &&
            loggedInUser?.role_id?.role_name === "superadmin"
          ) {
            req.url = `${req.url}&domainId=all`;
          } else if (
            req.url.includes("/orders/search") &&
            actAsDomainIdMemoised &&
            loggedInUser?.role_id?.role_name === "superadmin"
          ) {
            req.url = `${req.url}&domainId=${actAsDomainIdMemoised}`;
          }

          let token;

          if (
            req.url.includes("/orders/credit-check") ||
            req.url.includes("/orders/product-allocation-check")
          ) {
            token = "Bearer allowed_to_call_dtn_api";
          } else {
            token = `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`;
          }

          req.headers = {
            ...req.headers,
            Authorization: token,
            actasdomainid: actAsDomainIdMemoised,
          };
          return req;
        }}
      />
    </div>
  );
}

export default Swagger;