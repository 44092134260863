import React, { useState, useMemo, useCallback, useEffect } from "react";
import clsx from "clsx";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Table from "../components/Table";
import AutoComplete from "../components/autocomplete";
import TerminalService from "../services/terminal.service";
import Spinner from "../components/circularProgress"
import ReplayFilterOrder from "../components/popup";
import Dropdown from "../components/dropdown";
import SummaryRow from "../components/summary/row";
import OrderService from "../services/order.service";
import DomainService from "../services/domain.service";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder, protocolVersionV4, protocolVersionsList } from "../utils/appConstants";
import MultiDropdown from "../components/multiDropdown";

function MangeOrders({ user, loggedInUser, actAsDomainIdMemoised }) {
  const defaultColumns = [
    "replay",
    "tandem_id",
    "order_status",
    "created_date",
    "metered_load.loading_number",
    "metered_load.order_number",
    "metered_load.terminal_control_number",
    "metered_load.driver_name",
    "metered_load.primary_trailer",
    "details",
  ];
  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
  const [disabledColumns, setDisabledColumns] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState({
    label: "All",
    value: "all",
  });
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [replayLoading, setReplayLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [driverNameError, setDriverNameError] = useState("");
  const [orderReplayDetails, setOrderReplayDetails] = useState({});
  const [newValues, setNewValues] = useState({
    tcn: "",
    driverName: "",
    loading_number: "",
    split_load: "",
    carrierId: "",
    customerId: "",
    vehicleTypeCode: "",
    shipperId: "",
    supplierId: "",
    primaryTrailer: "",
    tractor: "",
    vehicleId: "",
    effectiveDate: "",
    expirationDate: "",
    protocolVersion: "",
    orderNumber: "",
  });
  const [filterValues, setFilterValues] = useState({
    driverName: "",
    customerId: "",
    shipperId: "",
    vehicleId: "",
    trailerId: "",
    orderNumber: "",
    tcns: [],
    effectiveDate: {
      start: null,
      end: null,
    },
    expirationDate: {
      start: null,
      end: null,
    },
    startDate: {
      start: null,
      end: null,
    },
  });
  const [tempFilterValues, setTempFilterValues] = useState({});
  const [terminalsInputValue, setTerminalsInputValue] = useState("");
  const [productCodes, setProductCodes] = useState([]);
  const [dateErrors, setDateErrors] = useState({
    effectiveDate: "",
    expirationDate: "",
  });

  const openModal = () => {
    setOpen(true);
  };

  const validateDate = (dateString, type, setDateErrors) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    setDateErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (dateString === "") {
        newErrors[type] = "";
        return newErrors;
      }

      if (!dateRegex.test(dateString)) {
        newErrors[type] = `Invalid format for ${
          type === "expirationDate" ? "Expiration Date" : "Effective Date"
        }. Please enter a date in the format YYYY-MM-DDTHH:mm:ss.sssZ.`;
      } else {
        newErrors[type] = "";
      }

      return newErrors;
    });
  };

  const openFilterModal = () => {
    setOpenFilter(true);
  };

  const closeModal = useCallback(() => {
    setOpen(false);
    setNewValues({
      tcn: "",
      driverName: "",
      loading_number: "",
      split_load: "",
      carrierId: "",
      customerId: "",
      vehicleTypeCode: "",
      shipperId: "",
      supplierId: "",
      primaryTrailer: "",
      tractor: "",
      vehicleId: "",
      effectiveDate: "",
      expirationDate: "",
      protocolVersion: "",
      orderNumber: "",
    });
    setProductCodes([]);
    setOrderReplayDetails({});
  }, []);

  const closeFilterModal = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const clearFilterModal = useCallback(() => {
    setFilterValues({
      driverName: "",
      customerId: "",
      shipperId: "",
      vehicleId: "",
      trailerId: "",
      orderNumber: "",
      tcns: [],
      effectiveDate: {
        start: null,
        end: null,
      },
      expirationDate: {
        start: null,
        end: null,
      },
      startDate: {
        start: null,
        end: null,
      },
    });
    setTempFilterValues({});
  }, []);

  const { order, orderBy, setOrder, setOrderBy } = useSorting({
    defaultOrder: SortOrder.desc,
    defaultOrderBy: "last_modified",
  });
  const { page, perPage, setPage, setPerPage } = usePagination({
    defaultPage: 1,
    defaultPerPage: 10,
  });

  const {
    data: orders,
    total,
    loading: ordersLoading,
  } = useFetch({
    service: OrderService,
    func: "getOrders",
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order,
      orderBy,
      page,
      perPage,
      domainId: actAsDomainIdMemoised || selectedDomain.value,
      actAsDomainId: actAsDomainIdMemoised,
      exportFilters: tempFilterValues,
    },
    dependency: [
      user?.signInUserSession?.accessToken?.jwtToken,
      order,
      orderBy,
      page,
      perPage,
      JSON.stringify(selectedDomain),
      reload,
      actAsDomainIdMemoised,
      JSON.stringify(tempFilterValues),
    ],
  });

  const { data: domains } = useFetch({
    service: DomainService,
    func: "getDomains",
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order: "ASC",
      orderBy: "domain_name",
      page: 1,
      perPage: 1000,
      searchText: "",
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken],
  });

  const headCells = [
    {
      id: "replay",
      accessor: "replay",
      align: "left",
      disablePadding: false,
      sortEnabled: false,
      label: "Action",
      width: "3%",
    },
    {
      id: "domain_id",
      accessor: "carrier_domain_id.domain_name",
      align: "left",
      disablePadding: false,
      sortEnabled: false,
      label: "Domain",
      width: "8%",
    },
    {
      id: "tandem_id",
      accessor: "tandem_id",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Tandem Id",
      width: "8%",
    },
    {
      id: "metered_load.carrier_id",
      accessor: "metered_load.carrier_id",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Carrier Id",
      width: "6%",
    },
    {
      id: "order_status",
      accessor: "order_status",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Order Status",
      width: "12%",
    },
    {
      id: "created_date",
      accessor: "created_date",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Created Date",
      width: "8%",
    },
    {
      id: "metered_load.driver_name",
      accessor: "metered_load.driver_name",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Driver Name",
      width: "8%",
    },
    {
      id: "metered_load.terminal_control_number",
      accessor: "metered_load.terminal_control_number",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Terminal Control Number",
      width: "15%",
    },
    {
      id: "metered_load.primary_trailer",
      accessor: "metered_load.primary_trailer",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Vehicle Id",
      width: "8%",
    },
    {
      id: "metered_load.customer_id",
      accessor: "metered_load.customer_id",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Customer Id",
      width: "5%",
    },
    {
      id: "metered_load.loading_number",
      accessor: "metered_load.loading_number",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Loading Number",
      width: "8%",
    },
    {
      id: "metered_load.supplier_id",
      accessor: "metered_load.supplier_id",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Supplier Id",
      width: "6%",
    },
    {
      id: "metered_load.order_number",
      accessor: "metered_load.order_number",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Order Number",
      width: "10%",
    },
    {
      id: "order_update_token_expired_at",
      accessor: "order_update_token_expired_at",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Expiration Date",
      width: "5%",
    },
    {
      id: "metered_load.effective_date",
      accessor: "metered_load.effective_date",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Effective Date",
      width: "5%",
    },
    {
      id: "details",
      accessor: "details",
      align: "center",
      disablePadding: false,
      sortEnabled: false,
      label: "Order Details",
      width: "10%",
    },
  ];

  const columnLabels = useMemo(() => {
    return headCells.reduce((acc, { id, label }) => {
      acc[id] = label;
      return acc;
    }, {});
  }, [headCells]);

  const renderSelectedLabels = useMemo(
    () => (selected) => {
      return selected
        .map((id) => columnLabels[id])
        .map((label, index) => (index > 0 ? `, ${label}` : label))
        .join("");
    },
    [columnLabels]
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value && value.length == 1) {
      setDisabledColumns(value);
    } else {
      setDisabledColumns([]);
    }
    setSelectedColumns(value);
  };

  const filteredHeadCells = useMemo(() => {
    return headCells.filter((col) => selectedColumns.includes(col.id));
  }, [selectedColumns]);

  const handleSelectedDomainChange = (e) => {
    setSelectedDomain(e.target.value);
    setPage(1);
  };

  const onReplyRowClick = useCallback(
    (orderId) => {
      const order = orders.find((orderItem) => orderItem._id === orderId);
      // console.log(order, 'order')
      setOrderReplayDetails(order || {});
      openModal();
    },
    [JSON.stringify(orders)]
  );

  const handleNewValuesChange = (event, key) => {
    setNewValues((prev) => {
      return {
        ...prev,
        [key]: event.target.value,
      };
    });
  };

  const handleNewValuesProductCodeChange = useCallback(
    (event, from) => {
      const existedValue = productCodes.find((item) => item.from === from);
      let tempProductCodes = [];

      if (existedValue) {
        tempProductCodes = productCodes.map((productCode) => {
          if (productCode.from === from) {
            return {
              ...productCode,
              to: event.target.value,
            };
          } else {
            return productCode;
          }
        });
      } else {
        tempProductCodes = [
          ...productCodes,
          {
            from,
            to: event.target.value,
          },
        ];
      }

      setProductCodes(tempProductCodes);
    },
    [JSON.stringify(productCodes)]
  );

  const payload = useMemo(() => {
    // console.log(orderReplayDetails)
    const tempPayload = {
      id: orderReplayDetails._id,
    };

    if (newValues.tcn) {
      tempPayload["terminal_control_number"] = newValues.tcn.trim();
    }

    if (newValues.driverName) {
      tempPayload["driver_name"] = newValues.driverName.trim();
    }

    if (newValues.loading_number) {
      tempPayload["loading_number"] = newValues.loading_number.trim();
    }

    if (newValues.split_load !== "") {
      tempPayload["split_load"] = newValues.split_load?.value;
    }

    if (newValues.carrierId) {
      tempPayload["carrier_id"] = newValues.carrierId.trim();
    }

    if (newValues.customerId) {
      tempPayload["customer_id"] = newValues.customerId.trim();
    }

    if (newValues.shipperId) {
      tempPayload["shipper_id"] = newValues.shipperId.trim();
    }

    if (newValues.supplierId) {
      tempPayload["supplier_id"] = newValues.supplierId.trim();
    }

    if (newValues.primaryTrailer) {
      tempPayload["primary_trailer"] = newValues.primaryTrailer.trim();
    }

    if (newValues.tractor) {
      tempPayload["tractor"] = newValues.tractor.trim();
    }

    if (newValues.vehicleId) {
      tempPayload["vehicle_id"] = newValues.vehicleId.trim();
    }

    if (newValues.effectiveDate) {
      tempPayload["effective_date"] = newValues.effectiveDate.trim();
    }

    if (newValues.expirationDate) {
      tempPayload["expiration_date"] = newValues.expirationDate.trim();
    }

    if (newValues.protocolVersion) {
      tempPayload["protocol_version"] = newValues.protocolVersion.trim();
    }

    if (newValues.orderNumber) {
      tempPayload["order_number"] = newValues.orderNumber.trim();
    }

    if (productCodes && productCodes.length) {
      tempPayload["product_codes"] = productCodes.map((productCode) => {
        return {
          ...productCode,
          to: productCode.to.trim(),
        };
      });
    }

    return tempPayload;
  }, [
    JSON.stringify(orderReplayDetails),
    JSON.stringify(newValues),
    JSON.stringify(productCodes),
    handleNewValuesChange,
  ]);

  const handleReplayOrder = useCallback(() => {
    // console.log(payload,'payload')
    setReplayLoading(true);

    OrderService.replayOrders({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      payload: payload,
    })
      .then((response) => {
        setReplayLoading(false);
        setReload((prev) => !prev);
        closeModal();
        toast.success("Order replayed successfully.", { icon: false });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message || err.message || "Something went wrong",
          { icon: false }
        );
        setReplayLoading(false);
      });
  }, [user?.signInUserSession?.accessToken?.jwtToken, JSON.stringify(payload)]);

  const exportOrders = useCallback(() => {
    setExportLoading(true);
    OrderService.exportOrders({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      domainId: actAsDomainIdMemoised || selectedDomain.value,
      actAsDomainIdMemoised,
      isManageOrders: true,
      exportFilters: tempFilterValues,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const exportLinkElement = document.createElement("a");

        exportLinkElement.hidden = true;
        exportLinkElement.download = `${
          actAsDomainIdMemoised || selectedDomain.label
        }.csv`;
        exportLinkElement.href = url;
        exportLinkElement.text = "downloading...";

        document.body.appendChild(exportLinkElement);
        exportLinkElement.click();
        setExportLoading(false);
        exportLinkElement.remove();
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setExportLoading(false);
      });
  }, [
    user?.signInUserSession?.accessToken?.jwtToken,
    selectedDomain,
    actAsDomainIdMemoised,
    JSON.stringify(tempFilterValues),
  ]);

  const replayModalTitle = "Replay Order";

  const replayModalContent = useMemo(() => {
    const meteredLoad = orderReplayDetails.metered_load || {};
    const translatedData = orderReplayDetails.translated_data || {};
    const split_load = {
      label: String(
        translatedData?.split_load?.from ??
          orderReplayDetails?.split_load ??
          "false"
      ),
      value:
        translatedData?.split_load?.from ??
        orderReplayDetails?.split_load ??
        false,
    };

    return (
      <div className="replay-order-root">
        <div className="replay-order">
          <div className="replay-left">
            <label className="title">Current Values</label>
            <div className="current-values-form">
              {protocolVersionsList.includes(meteredLoad?.protocol_version) && (
                <div className="form-field-box">
                  <label>Split Load</label>
                  <Dropdown
                    options={[
                      {
                        label: "true",
                        value: true,
                      },
                      {
                        label: "false",
                        value: false,
                      },
                    ]}
                    disabled={true}
                    value={split_load}
                    useCustomStyles={true}
                    className={"domain-selection"}
                  />
                  {translatedData?.split_load &&
                    ((translatedData?.split_load?.success !== undefined && !translatedData?.split_load?.success) || translatedData?.split_load?.error) && (
                      <label className="d-error">
                        Split Load configuration is invalid
                      </label>
                    )}
                </div>
              )}

              {protocolVersionsList.includes(meteredLoad?.protocol_version) && (
                <div className="form-field-box">
                  <label>Carrier Id</label>
                  <input
                    value={
                      translatedData?.carrier_id?.from ||
                      meteredLoad?.carrier_id
                    }
                    disabled
                    className="disabled"
                  />
                  {translatedData?.carrier_id &&
                    ((translatedData?.carrier_id?.success !== undefined && !translatedData?.carrier_id?.success) || translatedData?.carrier_id?.error) && (
                      <label className="d-error">Carrier Id is invalid</label>
                    )}
                </div>
              )}

              <div className="form-field-box">
                <label>Terminal Identifier</label>
                <input
                  value={
                    meteredLoad.terminal_control_number ||
                    meteredLoad.terminal_id
                  }
                  disabled
                  className="disabled"
                />
                {translatedData?.terminal_control_number &&
                  ((translatedData?.terminal_control_number?.success !== undefined && !translatedData?.terminal_control_number?.success) || translatedData?.terminal_control_number?.error) && (
                    <label className="d-error">
                      Terminal Identifier is invalid
                    </label>
                  )}
              </div>
              <div className="form-field-box">
                <label>Driver Name</label>
                <input
                  value={
                    translatedData?.driver_name?.from ||
                    meteredLoad?.driver_name
                  }
                  disabled
                  className="disabled"
                />
                {translatedData?.driver_name && (
                  ((translatedData?.driver_name?.success !== undefined && !translatedData?.driver_name?.success) || translatedData?.driver_name?.error) && (
                    <label className="d-error">
                      {translatedData?.driver_name?.error || "Driver Name is invalid"}
                    </label>
                  )
                )}

              </div>
              {!protocolVersionsList.includes(
                meteredLoad?.protocol_version
              ) && (
                  <div className="form-field-box">
                    <label>Customer ID</label>
                    <input
                      value={
                        translatedData?.customer_id?.from ||
                        meteredLoad?.customer_id
                      }
                      disabled
                      className="disabled"
                    />
                    {translatedData?.customer_id &&
                      ((translatedData?.customer_id?.success !== undefined && !translatedData?.customer_id?.success) || translatedData?.customer_id?.error) && (
                        <label className="d-error">Customer ID is invalid</label>
                      )}
                  </div>
                )}
              {protocolVersionsList.includes(meteredLoad?.protocol_version) ? (
                <div className="form-field-box">
                  <label>Supplier ID</label>
                  <input
                    value={
                      translatedData?.supplier_id?.from ||
                      meteredLoad?.supplier_id
                    }
                    disabled
                    className="disabled"
                  />
                  {translatedData?.supplier_id &&
                    ((translatedData?.supplier_id?.success !== undefined && !translatedData?.supplier_id?.success) || translatedData?.supplier_id?.error) && (
                      <label className="d-error">Supplier ID is invalid</label>
                    )}
                </div>
              ) : (
                <div className="form-field-box">
                  <label>Shipper ID</label>
                  <input
                    value={
                      translatedData?.shipper_id?.from ||
                      meteredLoad?.shipper_id
                    }
                    disabled
                    className="disabled"
                  />
                  {translatedData?.shipper_id &&
                    ((translatedData?.shipper_id?.success !== undefined && !translatedData?.shipper_id?.success) || translatedData?.shipper_id?.error) && (
                      <label className="d-error">Shipper ID is invalid</label>
                    )}
                </div>
              )}

              {protocolVersionsList.includes(meteredLoad?.protocol_version) ? (
                meteredLoad?.protocol_version === protocolVersionV4 ? (
                  <div className="form-field-box">
                    <label>Tractor</label>
                    <input
                      value={
                        translatedData?.tractor?.from || meteredLoad?.tractor
                      }
                      disabled
                      className="disabled"
                    />
                    {translatedData?.tractor &&
                      ((translatedData?.tractor?.success !== undefined && !translatedData?.tractor?.success) || translatedData?.tractor?.error) && (
                        <label className="d-error">Tractor is invalid</label>
                      )}
                  </div>
                ) : (
                  <div className="form-field-box">
                    <label>Primary Trailer</label>
                    <input
                      value={
                        translatedData?.primary_trailer?.from ||
                        meteredLoad?.primary_trailer
                      }
                      disabled
                      className="disabled"
                    />
                    {translatedData?.primary_trailer &&
                      ((translatedData?.primary_trailer?.success !== undefined && !translatedData?.primary_trailer?.success) || translatedData?.primary_trailer?.error) && (
                        <label className="d-error">
                          Primary Trailer is invalid
                        </label>
                      )}
                  </div>
                )
              ) : (
                translatedData?.vehicle_id && (
                  <div className="form-field-box">
                    <label>Vehicle ID</label>
                    <input
                      value={
                        translatedData?.vehicle_id?.from ||
                        meteredLoad?.vehicle_id
                      }
                      disabled
                      className="disabled"
                    />
                    {translatedData?.vehicle_id &&
                      ((translatedData?.vehicle_id?.success !== undefined && !translatedData?.vehicle_id?.success) || translatedData?.vehicle_id?.error) && (
                        <label className="d-error">Vehicle ID is invalid</label>
                      )}
                  </div>
                )
              )}
              <div className="form-field-box">
                <label>Effective Date</label>
                <input
                  value={
                    translatedData?.effective_date?.from ||
                    meteredLoad?.effective_date
                  }
                  disabled
                  className="disabled"
                />
                {translatedData?.effective_date &&
                  ((translatedData?.effective_date?.success !== undefined && !translatedData?.effective_date?.success) || translatedData?.effective_date?.error) && (
                    <label className="d-error">Effective Date is invalid</label>
                  )}
              </div>
              <div className="form-field-box">
                <label>Expiration Date</label>
                <input
                  value={
                    translatedData?.expiration_date?.from ||
                    orderReplayDetails?.order_update_token_expired_at
                  }
                  disabled
                  className="disabled"
                />
                {translatedData?.expiration_date &&
                  ((translatedData?.expiration_date?.success !== undefined && !translatedData?.expiration_date?.success) || translatedData?.expiration_date?.error) && (
                    <label className="d-error">
                      Expiration Date is invalid
                    </label>
                  )}
              </div>
              <div className="form-field-box">
                <label>Protocol Version</label>
                <input
                  value={
                    translatedData?.protocol_version?.from ||
                    meteredLoad?.protocol_version
                  }
                  disabled
                  className="disabled"
                />
                {translatedData?.protocol_version &&
                  ((translatedData?.protocol_version?.success !== undefined && !translatedData?.protocol_version?.success) || translatedData?.protocol_version?.error) && (
                    <label className="d-error">
                      Protocol Version is invalid
                    </label>
                  )}
              </div>
              <div className="form-field-box">
                <label>Order Number</label>
                <input
                  value={
                    translatedData?.order_number?.from ||
                    meteredLoad?.order_number
                  }
                  disabled
                  className="disabled"
                />
                {translatedData?.order_number &&
                  ((translatedData?.order_number?.success !== undefined && !translatedData?.order_number?.success) || translatedData?.order_number?.error) && (
                    <label className="d-error">Order Number is invalid</label>
                  )}
              </div>

              {protocolVersionsList.includes(meteredLoad?.protocol_version) && (
                <div className="form-field-box">
                  <label>Loading Number</label>
                  <input
                    value={
                      translatedData?.loading_number?.from ||
                      meteredLoad?.loading_number
                    }
                    disabled
                    className="disabled"
                  />
                  {translatedData?.loading_number &&
                    ((translatedData?.loading_number?.success !== undefined && !translatedData?.loading_number?.success) || translatedData?.loading_number?.error) && (
                      <label className="d-error">
                        Loading Number is invalid
                      </label>
                    )}
                </div>
              )}
            </div>
          </div>
          <div className="break-line"></div>
          <div className="replay-right">
            <label className="title">New Values</label>
            <div className="new-values-form">
              {protocolVersionsList.includes(meteredLoad?.protocol_version) && (
                <div
                  className={clsx(
                    "form-field-box",
                    translatedData?.split_load?.error && "margin-bottom"
                  )}
                >
                  <label>Split Load</label>
                  <Dropdown
                    options={[
                      {
                        label: "true",
                        value: true,
                      },
                      {
                        label: "false",
                        value: false,
                      },
                    ]}
                    value={newValues.split_load}
                    onChange={(newValue) =>
                      handleNewValuesChange(
                        { target: { value: newValue } },
                        "split_load"
                      )
                    }
                    className={"domain-selection"}
                  />
                </div>
              )}

              {protocolVersionsList.includes(meteredLoad?.protocol_version) && (
                <div
                  className={clsx(
                    "form-field-box",
                    translatedData?.carrier_id?.error && "margin-bottom"
                  )}
                >
                  <label>Carrier Id</label>
                  <input
                    value={newValues.carrierId}
                    onChange={(e) => handleNewValuesChange(e, "carrierId")}
                    placeholder="Enter Carrier Id"
                  />
                </div>
              )}

              <div
                className={clsx(
                  "form-field-box",
                  translatedData?.terminal_control_number?.error &&
                  "margin-bottom"
                )}
              >
                <label>Terminal Identifier</label>
                <input
                  value={newValues.tcn}
                  onChange={(e) => handleNewValuesChange(e, "tcn")}
                  placeholder="Enter Terminal Identifier"
                />
              </div>
              <div
                className={clsx(
                  "form-field-box",
                  translatedData?.driver_name?.error && "margin-bottom"
                )}
              >
                <label>Driver Name</label>
                <input
                  value={newValues.driverName}
                  onChange={(e) => handleNewValuesChange(e, "driverName")}
                  placeholder="Enter Driver Name"
                />
                {driverNameError && (
                  <label className="d-error">{driverNameError}</label>
                )}
              </div>
              {(!protocolVersionsList.includes(meteredLoad?.protocol_version) ||
                meteredLoad?.customer_id) && (
                  <div
                    className={clsx(
                      "form-field-box",
                      translatedData?.customer_id?.error && "margin-bottom"
                    )}
                  >
                    <label>Customer ID</label>
                    <input
                      value={newValues.customerId}
                      onChange={(e) => handleNewValuesChange(e, "customerId")}
                      placeholder="Enter Customer ID"
                    />
                  </div>
                )}
              {protocolVersionsList.includes(meteredLoad?.protocol_version) ? (
                <div
                  className={clsx(
                    "form-field-box",
                    translatedData?.supplier_id?.error && "margin-bottom"
                  )}
                >
                  <label>Supplier ID</label>
                  <input
                    value={newValues.supplierId}
                    onChange={(e) => handleNewValuesChange(e, "supplierId")}
                    placeholder={`Enter Supplier ID`}
                  />
                </div>
              ) : (
                <div
                  className={clsx(
                    "form-field-box",
                    translatedData?.shipper_id?.error && "margin-bottom"
                  )}
                >
                  <label>
                    {protocolVersionsList.includes(
                      meteredLoad?.protocol_version
                    )
                      ? "Supplier"
                      : "Shipper"}{" "}
                    ID
                  </label>
                  <input
                    value={newValues.shipperId}
                    onChange={(e) => handleNewValuesChange(e, "shipperId")}
                    placeholder={`Enter ${protocolVersionsList.includes(
                      meteredLoad?.protocol_version
                    )
                      ? "Supplier ID"
                      : "Shipper ID"
                      }`}
                  />
                </div>
              )}

              {translatedData?.vehicle_id &&
                !protocolVersionsList.includes(meteredLoad?.protocol_version) ? (
                <div
                  className={clsx(
                    "form-field-box",
                    translatedData?.vehicle_id?.error && "margin-bottom"
                  )}
                >
                  <label>
                    {protocolVersionsList.includes(
                      meteredLoad?.protocol_version
                    )
                      ? "Primary Trailer"
                      : "Vehicle ID"}
                  </label>
                  <input
                    value={newValues.vehicleId}
                    onChange={(e) => handleNewValuesChange(e, "vehicleId")}
                    placeholder={`Enter Vehicle ID`}
                  />
                </div>
              ) : (
                protocolVersionsList.includes(meteredLoad?.protocol_version) &&
                (protocolVersionV4 === meteredLoad?.protocol_version ? (
                  <div
                    className={clsx(
                      "form-field-box",
                      translatedData?.tractor?.error && "margin-bottom"
                    )}
                  >
                    <label>Tractor</label>
                    <input
                      value={newValues.tractor}
                      onChange={(e) => handleNewValuesChange(e, "tractor")}
                      placeholder={`Enter Tractor`}
                    />
                  </div>
                ) : (
                  <div
                    className={clsx(
                      "form-field-box",
                      translatedData?.primary_trailer?.error && "margin-bottom"
                    )}
                  >
                    <label>Primary Trailer</label>
                    <input
                      value={newValues.primaryTrailer}
                      onChange={(e) =>
                        handleNewValuesChange(e, "primaryTrailer")
                      }
                      placeholder={`Enter Primary Trailer`}
                    />
                  </div>
                ))
              )}
              <div
                className={clsx(
                  "form-field-box",
                  translatedData?.effective_date?.error && "margin-bottom"
                )}
              >
                <label>Effective Date</label>
                <input
                  value={newValues.effectiveDate}
                  onChange={(e) => {
                    handleNewValuesChange(e, "effectiveDate");
                    validateDate(
                      e.target.value,
                      "effectiveDate",
                      setDateErrors
                    );
                  }}
                  placeholder="Enter Effective Date"
                />
                {dateErrors.effectiveDate && (
                  <label className="d-error">{dateErrors.effectiveDate}</label>
                )}
              </div>
              <div
                className={clsx(
                  "form-field-box",
                  translatedData?.expiration_date?.error && "margin-bottom"
                )}
              >
                <label>Expiration Date</label>
                <input
                  value={newValues.expirationDate}
                  onChange={(e) => {
                    handleNewValuesChange(e, "expirationDate");
                    validateDate(
                      e.target.value,
                      "expirationDate",
                      setDateErrors
                    );
                  }}
                  placeholder="Enter Expiration Date"
                />
                {dateErrors.expirationDate && (
                  <label className="d-error">{dateErrors.expirationDate}</label>
                )}
              </div>
              <div
                className={clsx(
                  "form-field-box",
                  translatedData?.protocol_version?.error && "margin-bottom"
                )}
              >
                <label>Protocol Version</label>
                <input
                  value={newValues.protocolVersion}
                  onChange={(e) => handleNewValuesChange(e, "protocolVersion")}
                  placeholder="Enter Protocol Version"
                />
              </div>
              <div
                className={clsx(
                  "form-field-box",
                  translatedData?.order_number?.error && "margin-bottom"
                )}
              >
                <label>Order Number</label>
                <input
                  value={newValues.orderNumber}
                  onChange={(e) => handleNewValuesChange(e, "orderNumber")}
                  placeholder="Enter Order Number"
                />
              </div>
              {protocolVersionsList.includes(meteredLoad?.protocol_version) && (
                <div
                  className={clsx(
                    "form-field-box",
                    translatedData?.loading_number?.error && "margin-bottom"
                  )}
                >
                  <label>Loading Number</label>
                  <input
                    value={newValues.loading_number}
                    onChange={(e) => handleNewValuesChange(e, "loading_number")}
                    placeholder="Enter Loading Number"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="product-codes">
          <label className="title">Product Codes</label>
          {(translatedData.product_code || []).map((productCode, index) => {
            const existedNewData = productCodes.find(
              (item) => item.from === productCode.from
            );
            return (
              <div key={index} className="product-code-main">
                <div className="product-code-child form-field-box">
                  <input
                    value={productCode.from}
                    disabled
                    className="disabled"
                  />
                  {productCode?.error && (
                    <label className="d-error">{productCode.error}</label>
                  )}
                </div>
                <div className="product-code-child">
                  <ArrowForwardIcon />
                </div>
                <div className="product-code-child">
                  <input
                    value={existedNewData ? existedNewData.to : ""}
                    onChange={(e) =>
                      handleNewValuesProductCodeChange(e, productCode.from)
                    }
                    placeholder="Enter Product Code"
                  />
                </div>
              </div>
            );
          })}
          {!protocolVersionsList.includes(meteredLoad?.protocol_version) &&
            translatedData?.shipper_id?.error &&
            (!translatedData.product_code ||
              !translatedData.product_code.length) && (
              <div className="form-field-box">
                <label className="d-error">
                  Product codes are not showing because of shipper id not
                  translated.
                </label>
              </div>
            )}
          {protocolVersionsList.includes(meteredLoad?.protocol_version) &&
            translatedData?.supplier_id?.error &&
            (!translatedData.product_code ||
              !translatedData.product_code.length) && (
              <div className="form-field-box">
                <label className="d-error">
                  Product codes are not showing because of supplier id not
                  translated.
                </label>
              </div>
            )}
        </div>
      </div>
    );
  }, [
    JSON.stringify(orderReplayDetails),
    JSON.stringify(newValues),
    JSON.stringify(productCodes),
    driverNameError,
    handleNewValuesChange,
  ]);

  const btnDisabled = useMemo(() => {
    let disabled = false;
    let driverError = "";
    if (
      !newValues.tcn.trim() &&
      !newValues.driverName.trim() &&
      !newValues.loading_number.trim() &&
      (!newValues.split_load || newValues.split_load === "") &&
      !newValues.customerId.trim() &&
      !newValues.carrierId.trim() &&
      !newValues.shipperId.trim() &&
      !newValues.primaryTrailer.trim() &&
      !newValues.tractor.trim() &&
      !newValues.supplierId.trim() &&
      !newValues.vehicleId.trim() &&
      !newValues.vehicleTypeCode.trim() &&
      !newValues.effectiveDate.trim() &&
      !newValues.expirationDate.trim() &&
      !newValues.protocolVersion.trim() &&
      !newValues.orderNumber.trim() &&
      (!productCodes ||
        !productCodes.length ||
        (productCodes && productCodes.every((item) => !item.to.trim())))
    ) {
      disabled = true;
    }

    if (newValues.driverName) {
      const driverNameTrimmed = newValues.driverName.trim();
      if (driverNameTrimmed.includes(",")) {
        const [lastName, firstName] = driverNameTrimmed.split(/,(.*)/s);
        if (!firstName || !lastName) {
          driverError =
            "Provided driver name is not valid, It should have firstname and lastname.";
          disabled = true;
        }
      } else {
        const [firstName, lastName] = driverNameTrimmed.split(/ (.*)/s);
        if (!firstName || !lastName) {
          driverError =
            "Provided driver name is not valid, It should have firstname and lastname.";
          disabled = true;
        }
      }
    }

    if (dateErrors.effectiveDate || dateErrors.expirationDate) {
      disabled = true;
    }

    setDriverNameError(driverError);
    return disabled;
  }, [
    JSON.stringify(newValues),
    JSON.stringify(productCodes),
    setDriverNameError,
  ]);

  const replayModalAction = useMemo(() => {
    let btnContent;

    if (replayLoading) {
      btnContent = <Spinner size={22} color="white" />;
    } else {
      btnContent = "Replay";
    }

    return (
      <div className="add-domain-btn-div">
        <button
          className={`btn add-domain-btn ${btnDisabled && "disabled"}`}
          disabled={btnDisabled}
          onClick={handleReplayOrder}
        >
          {btnContent}
        </button>
      </div>
    );
  }, [btnDisabled, replayLoading, handleReplayOrder]);

  const { data: terminalOptions } = useFetch({
    service: TerminalService,
    func: "getTerminals",
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      searchText: terminalsInputValue,
    },
    dependency: [
      user?.signInUserSession?.accessToken?.jwtToken,
      terminalsInputValue,
    ],
  });

  const handleFilterValuesChange = (event, key) => {
    setFilterValues((prev) => {
      return {
        ...prev,
        [key]: event.target.value,
      };
    });
  };

  const handleTerminalsInputChange = (val) => {
    setTerminalsInputValue(val);
  };

  const handleTerminalSelectionChange = useCallback(
    (terminals) => {
      setFilterValues((item) => {
        return {
          ...item,
          tcns: terminals,
        };
      });
      handleTerminalsInputChange(terminalsInputValue);
    },
    [terminalsInputValue]
  );

  const handleDatesChange = useCallback(
    (date, typeOfDate, dateField) => {
      setFilterValues((item) => {
        return {
          ...item,
          [dateField]: {
            ...item[dateField],
            [typeOfDate]: date,
          },
        };
      });
    },
    [setFilterValues]
  );

  const loadOptions = (inputValue) => {
    return new Promise((resolve, rej) => {
      TerminalService.getTerminals({
        token: user?.signInUserSession?.accessToken?.jwtToken,
        searchText: inputValue,
      })
        .then((res) => {
          const tempTerminalOptions = (res.data.data || []).map((item) => {
            return { value: item._id, label: item.control_number };
          });
          resolve(tempTerminalOptions);
        })
        .catch((err) => {
          resolve([]);
        });
    });
  };

  const filterBtnDisabled = useMemo(() => {
    let disabled = false;
    if (
      !filterValues.driverName.trim() &&
      !filterValues.orderNumber.trim() &&
      !filterValues.customerId.trim() &&
      !filterValues.shipperId.trim() &&
      !filterValues.vehicleId.trim() &&
      !filterValues.trailerId.trim() &&
      (!filterValues.tcns || !filterValues.tcns.length) &&
      !filterValues.effectiveDate.start &&
      !filterValues.effectiveDate.end &&
      !filterValues.expirationDate.start &&
      !filterValues.expirationDate.end &&
      !filterValues.startDate.start &&
      !filterValues.startDate.end
    ) {
      disabled = true;
    }
    return disabled;
  }, [JSON.stringify(filterValues)]);

  const setFilterValuesForApi = useCallback(() => {
    const refactorFilterValues = {
      ...filterValues,
      tcns: filterValues.tcns.map((item) => item.label),
    };
    setTempFilterValues(refactorFilterValues);
    closeFilterModal();
  }, [JSON.stringify(filterValues), closeFilterModal]);

  const filterModalAction = useMemo(() => {
    let btnContent;

    if (ordersLoading) {
      btnContent = <Spinner size={22} color="white" />;
    } else {
      btnContent = "Filter";
    }

    return (
      <div className="add-domain-btn-div">
        <button
          className={`btn add-domain-btn ${filterBtnDisabled && "disabled"}`}
          disabled={filterBtnDisabled}
          onClick={setFilterValuesForApi}
        >
          {btnContent}
        </button>
        <button
          className={`btn add-domain-btn btn-clear`}
          onClick={clearFilterModal}
        >
          {"Clear"}
        </button>
      </div>
    );
  }, [filterBtnDisabled, ordersLoading, setFilterValuesForApi]);

  const filterModalTitle = "Filter Orders";

  const filterModalContent = useMemo(() => {
    return (
      <div className="filter-orders-root">
        <div className="date-filters">
          <div className="form-field-box">
            <label>Effective Date</label>
          </div>
          <div className="form-field-box">
            <DatePicker
              selected={filterValues.effectiveDate.start}
              onChange={(date, event) =>
                handleDatesChange(date, "start", "effectiveDate")
              }
              placeholderText="Start Date"
              maxDate={filterValues.effectiveDate.end}
            />
          </div>
          <div className="form-field-box">
            <DatePicker
              selected={filterValues.effectiveDate.end}
              onChange={(date, event) =>
                handleDatesChange(date, "end", "effectiveDate")
              }
              placeholderText="End Date"
              minDate={filterValues.effectiveDate.start}
            />
          </div>
        </div>
        <div className="date-filters">
          <div className="form-field-box">
            <label>Expiration Date</label>
          </div>
          <div className="form-field-box">
            <DatePicker
              selected={filterValues.expirationDate.start}
              onChange={(date, event) =>
                handleDatesChange(date, "start", "expirationDate")
              }
              placeholderText="Start Date"
              maxDate={filterValues.expirationDate.end}
            />
          </div>
          <div className="form-field-box">
            <DatePicker
              selected={filterValues.expirationDate.end}
              onChange={(date, event) =>
                handleDatesChange(date, "end", "expirationDate")
              }
              placeholderText="End Date"
              minDate={filterValues.expirationDate.start}
            />
          </div>
        </div>
        <div className="date-filters">
          <div className="form-field-box">
            <label>Start Date</label>
          </div>
          <div className="form-field-box">
            <DatePicker
              selected={filterValues.startDate.start}
              onChange={(date, event) =>
                handleDatesChange(date, "start", "startDate")
              }
              placeholderText="Start Date"
              maxDate={filterValues.startDate.end}
            />
          </div>
          <div className="form-field-box">
            <DatePicker
              selected={filterValues.startDate.end}
              onChange={(date, event) =>
                handleDatesChange(date, "end", "startDate")
              }
              placeholderText="End Date"
              minDate={filterValues.startDate.start}
            />
          </div>
        </div>
        <div className="separator-div"></div>
        <div className="row-filters">
          <div className="form-field-box">
            <label>Order Number</label>
            <input
              value={filterValues.orderNumber}
              onChange={(e) => handleFilterValuesChange(e, "orderNumber")}
              placeholder="Enter Order Number"
            />
          </div>
          <div className="form-field-box">
            <label>Customer Id</label>
            <input
              value={filterValues.customerId}
              onChange={(e) => handleFilterValuesChange(e, "customerId")}
              placeholder="Enter Customer Id"
            />
          </div>
          <div className="form-field-box">
            <label>Shipper Id</label>
            <input
              value={filterValues.shipperId}
              onChange={(e) => handleFilterValuesChange(e, "shipperId")}
              placeholder="Enter Shipper Id"
            />
          </div>
        </div>
        <div className="row-filters">
          <div className="form-field-box">
            <label>Vehicle Id</label>
            <input
              value={filterValues.vehicleId}
              onChange={(e) => handleFilterValuesChange(e, "vehicleId")}
              placeholder="Enter Vehicle Id"
            />
          </div>
          <div className="form-field-box">
            <label>Trailer Id</label>
            <input
              value={filterValues.trailerId}
              onChange={(e) => handleFilterValuesChange(e, "trailerId")}
              placeholder="Enter Trailer Id"
            />
          </div>
          <div className="form-field-box">
            <label>Driver Name</label>
            <input
              value={filterValues.driverName}
              onChange={(e) => handleFilterValuesChange(e, "driverName")}
              placeholder="Enter Driver Name"
            />
          </div>
        </div>
        <div className="separator-div"></div>
        <div className="form-field-box">
          <label>TCN</label>
          <AutoComplete
            loadOptions={loadOptions}
            defaultOptions={terminalOptions.map((item) => {
              return { value: item._id, label: item.control_number };
            })}
            onChange={handleTerminalSelectionChange}
            value={filterValues.tcns}
            onInputChange={handleTerminalsInputChange}
            inputValue={terminalsInputValue}
          />
        </div>
      </div>
    );
  }, [
    JSON.stringify(filterValues),
    terminalsInputValue,
    JSON.stringify(terminalOptions),
  ]);

  useEffect(() => {
    setPage(1);
  }, [actAsDomainIdMemoised]);

  return (
    <div className="summary-root">
      <div className="table-layout orders-table">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Orders</h3>
          {
            <div className="filter-box">
              <div className="column-selector">
                <MultiDropdown
                  headCells={headCells}
                  selectedColumns={selectedColumns}
                  disabledColumns={disabledColumns}
                  renderSelectedLabels={renderSelectedLabels}
                  handleChange={handleChange}
                />
              </div>
              {user.attributes["custom:role"] === "superadmin" &&
                !actAsDomainIdMemoised && (
                  <Dropdown
                    options={[
                      {
                        label: "All",
                        value: "all",
                      },
                      ...domains.map((item) => {
                        const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ""
                          }`;
                        return {
                          value: item._id,
                          label,
                        };
                      }),
                    ]}
                    value={selectedDomain}
                    onChange={(newValue) =>
                      handleSelectedDomainChange({
                        target: { value: newValue },
                      })
                    }
                    searchable
                    className={"domain-selection"}
                  />
                )}
              <button className={`btn`} onClick={openFilterModal}>
                {"Filter"}
              </button>
              <button className={`btn`} onClick={exportOrders}>
                {exportLoading ? <Spinner size={22} color="white" /> : "Export"}
              </button>
            </div>
          }
        </div>
      </div>
      <div className="table-layout orders-table">
        <div className="orders-table-main orders-table-layout">
          <Table
            data={orders}
            loading={ordersLoading}
            total={total}
            perPage={perPage}
            page={page}
            headCells={filteredHeadCells}
            order={order}
            orderBy={orderBy}
            setPage={setPage}
            setPerPage={setPerPage}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={SummaryRow}
            rowProps={{
              user,
              onReplyRowClick,
              isOrdersTable: true,
            }}
            noDataFoundMessage={`No Orders Found`}
            isOrdersTable={true}
          />
        </div>
      </div>
      <ReplayFilterOrder
        open={open}
        title={replayModalTitle}
        content={replayModalContent}
        action={replayModalAction}
        paperClassName={"add-driver-paper"}
        maxWidth={"lg"}
        onClose={closeModal}
      />

      <ReplayFilterOrder
        open={openFilter}
        title={filterModalTitle}
        content={filterModalContent}
        action={filterModalAction}
        paperClassName={"add-driver-paper"}
        maxWidth={"lg"}
        onClose={closeFilterModal}
      />
    </div>
  );
}

export default MangeOrders;