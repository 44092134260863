import React, { useState, useCallback, useMemo, useEffect } from "react";
import { toast } from 'react-toastify';
import ImportModal from "../components/popup";
import Table from "../components/Table";
import Dropdown from "../components/dropdown";
import Spinner from "../components/circularProgress";
import ManageLoadingNumberRow from "../components/manageLoadingNumbers/row";
import DomainService from "../services/domain.service";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder, SUPER_ADMIN } from "../utils/appConstants";
import LoadingNumberService from "../services/loadingNumber.service";
import MultiDropdown from "../components/multiDropdown";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ManageLoadingNumber = ({ user, actAsDomainIdMemoised, loggedInUser }) => {
  const defaultColumns = ['carrier_id.carrier_alias', 'loading_number', 'tcn_id.control_number', 'customer_id', 'created_date', 'fetch_supplier'];
  const disabledColumns = ['carrier_id.carrier_alias', 'tcn_id.control_number', 'loading_number'];
  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
  const [selectedDomain, setSelectedDomain] = useState({
    label: 'All',
    value: 'all'
  })
  const modalTitle = 'Import'
  const [open, setOpen] = useState(false);
  const [fileKey, setFileKey] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [selectedLoadingNumber, setSelectedLoadingNumber] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCheckboxValue, setSelectedCheckboxValue] = useState(false);

  const handleCheckboxChange = (data, value) => {
    setSelectedLoadingNumber(data);
    setSelectedCheckboxValue(value);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedLoadingNumber(null);
  };

  const handleDialogConfirm = () => {
    setOpenDialog(false);
    if (selectedLoadingNumber) {
      fetchSupplier(selectedLoadingNumber, selectedCheckboxValue);
    }
  };

  const fetchSupplier = useCallback((data, isChecked) => {
    LoadingNumberService.fetchSupplierFromLoadingNumber({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      payload: {
        id: data._id,
        isFetch: isChecked,
      }
    })
      .then(() => {
        toast.success('Supplier fetch action completed successfully.', { icon: false });
        setReload((prev) => !prev); // Refresh table
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || 'Something went wrong', { icon: false });
      });
  }, [user?.signInUserSession?.accessToken?.jwtToken]);

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
    setFileKey('');
  }

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.asc, defaultOrderBy: 'created_date' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: publicCustomerIdsMapping, loading, total } = useFetch({
    service: LoadingNumberService,
    func: 'getLoadingNumbers',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage,
      carrierIdFilter: actAsDomainIdMemoised || ((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) ? selectedDomain.value : loggedInUser.domain_id)
    },
    dependency: [reload, user?.signInUserSession?.accessToken?.jwtToken, order, orderBy, page, perPage, selectedDomain.value, loggedInUser.domain_id, user.attributes['custom:role'], actAsDomainIdMemoised],
    skip: user.attributes['custom:role'] !== "superadmin" && !loggedInUser.domain_id
  });

  const { data: domains } = useFetch({
    service: DomainService,
    func: 'getDomains',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order: 'ASC',
      orderBy: 'domain_name',
      page: 1,
      perPage: 1000,
      searchText: ''
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken]
  });

  const handleSelectedDomainChange = (e) => {
    setSelectedDomain(e.target.value)
    setPage(1)
  }

  const headCells = [{
    id: 'carrier_id.carrier_alias',
    accessor: 'carrier_id.carrier_alias',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Carrier Alias",
    width: "12%",
  }, {
    id: 'tcn_id.control_number',
    accessor: 'tcn_id.control_number',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Terminal Control Number",
    width: "12%",
  }, {
    id: 'loading_number',
    accessor: 'loading_number',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Loading Number",
    width: "16%",
  }, {
    id: 'customer_id',
    accessor: 'customer_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Customer Id",
    width: "12%",
  }, {
    id: 'supplier_id',
    accessor: 'supplier_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Supplier Id",
    width: "12%",
  }, {
    id: 'carrier_product_code',
    accessor: 'carrier_product_code',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Carrier Product Code",
    width: "12%",
  }, {
    id: 'terminal_product_code',
    accessor: 'terminal_product_code',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Terminal Product Code",
    width: "12%",
  }, {
    id: 'created_date',
    accessor: 'created_date',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Created Date",
    width: "12%",
  }, {
    id: 'fetch_supplier',
    accessor: 'is_fetch_supplier',
    align: 'center',
    disablePadding: false,
    sortEnabled: false,
    label: 'Fetch Supplier',
    width: '10%',
  },]

  const exportCarrierIdsToTcn = useCallback(() => {
    setExportLoading(true)
    LoadingNumberService.exportData({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      carrierId: selectedDomain.value
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response?.data]))
        const exportLinkElement = document.createElement('a');

        exportLinkElement.hidden = true;
        exportLinkElement.download = `${selectedDomain.label}.csv`;
        exportLinkElement.href = url;
        exportLinkElement.text = "downloading...";

        document.body.appendChild(exportLinkElement);
        exportLinkElement.click();
        setExportLoading(false)
        exportLinkElement.remove();
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setExportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, selectedDomain])

  const importCarrierIdsToTcn = useCallback((file) => {
    setImportLoading(true);
    LoadingNumberService.importData({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      payload: {
        domainId: selectedDomain.value,
        fileKey: fileKey
      }
    })
      .then(response => {
        toast.success("File imported successfully.", { icon: false });
        setImportLoading(false);
        setReload(prev => !prev)
        setPage(1)
        closeModal()
      })
      .catch((err) => {
        toast.error(err.response?.data.message || "Something went wrong", { icon: false });
        setImportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, selectedDomain, fileKey])

  const handleFileKeyChange = (e) => {
    setFileKey(e.target.value);
  }

  const modalContent = useMemo(() => {
    return (
      <div className="add-domain">
        <div className="form-field-box">
          <label>S3 File Key</label>
          <input value={fileKey} onChange={handleFileKeyChange} placeholder="S3 File Key" />
          <label className="d-error">{!fileKey.trim() && 'Please Enter S3 File Key'}</label>
        </div>
      </div>
    )
  }, [fileKey])

  const modalAction = useMemo(() => {
    let btnContent;

    if (importLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      btnContent = "Import"
    }

    return (
      <div className="add-domain-btn-div">
        <button className={`btn add-domain-btn ${!fileKey && 'disabled'}`} disabled={!fileKey} onClick={importCarrierIdsToTcn}>
          {btnContent}
        </button>
      </div>
    )
  }, [fileKey, importLoading])

  const columnLabels = useMemo(() => {
    return headCells.reduce((acc, { id, label }) => {
      acc[id] = label;
      return acc;
    }, {});
  }, [headCells]);

  const renderSelectedLabels = useMemo(() => (selected) => {
    return selected.map(id => columnLabels[id])
      .map((label, index) => (index > 0 ? `, ${label}` : label))
      .join('');
  }, [columnLabels]);

  const handleChange = (event) => {
    const { target: { value } } = event;
    setSelectedColumns(value);

  }

  const filteredHeadCells = useMemo(() => {
    // Only include 'fetch_supplier' for SUPER_ADMIN users
    return headCells.filter(col => {
      if (col.id === 'fetch_supplier' && loggedInUser?.role_id?.role_name !== SUPER_ADMIN) {
        return false;
      }
      return selectedColumns.includes(col.id);
    });
  }, [selectedColumns, loggedInUser?.role_id?.role_name]);


  useEffect(() => {
    setPage(1)
  }, [actAsDomainIdMemoised])

  return (
    <div className="root">
      <div className="table-layout">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Loading Number</h3>

          <div className="filter-box">
            <div className="column-selector">
              <MultiDropdown
                headCells={headCells}
                selectedColumns={selectedColumns}
                disabledColumns={disabledColumns}
                renderSelectedLabels={renderSelectedLabels}
                handleChange={handleChange}
              />
            </div>
            {((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) && !actAsDomainIdMemoised) && <>
              <Dropdown
                options={
                  [
                    {
                      label: 'All',
                      value: 'all'
                    },
                    ...domains.map(item => {
                      const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
                      return {
                        value: item._id,
                        label
                      }
                    })
                  ]
                }
                value={selectedDomain}
                onChange={(newValue) => handleSelectedDomainChange({ target: { value: newValue } })}
                searchable
                className={'domain-selection'}
              />
              {
                user.attributes['custom:role'] === "superadmin" && <>
                  <button className={`btn`} onClick={openModal}>Import</button>
                  <button className={`btn`} onClick={exportCarrierIdsToTcn}>
                    {
                      exportLoading ? <Spinner size={22} color="white" /> : 'Export'
                    }
                  </button>
                </>
              }
            </>}
          </div>

        </div>
        <div className="users-table">
          <Table
            data={publicCustomerIdsMapping}
            loading={loading || !loggedInUser?._id}
            total={total}
            perPage={perPage}
            page={page}
            headCells={filteredHeadCells}
            order={order}
            orderBy={orderBy}
            setPage={setPage}
            setPerPage={setPerPage}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={ManageLoadingNumberRow}
            rowProps={{
              loggedInUser,
              onCheckboxChange: handleCheckboxChange,
            }}
            noDataFoundMessage={`No Records Found`}
          />
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Fetch Suppliers"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Are you sure you want to ${selectedCheckboxValue ? 'enable' : 'disable'} fetching suppliers for this loading number?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>No</Button>
              <Button className="btn" onClick={handleDialogConfirm} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <ImportModal
        open={open}
        title={modalTitle}
        content={modalContent}
        action={modalAction}
        onClose={closeModal}
      />
    </div>
  )
};

export default ManageLoadingNumber;