import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AddEditUser from "../components/popup";
import Spinner from "../components/circularProgress";
import Table from "../components/Table";
import Dropdown from "../components/dropdown";
import UserRow from "../components/manageUsers/row";
import UserService from "../services/user.service";
import DomainService from "../services/domain.service";
import RoleService from "../services/role.service";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder, SUPER_ADMIN } from "../utils/appConstants";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { Switch } from "@mui/material";

const ManageUsers = ({ user, actAsDomainIdMemoised, loggedInUser }) => {
  //eslint-disable-next-line

  const [open, setOpen] = useState(false);
  const [added, setAdded] = useState(false);
  const [inviteUserLoading, setInviteUserLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [role_id, setRoleId] = useState("");
  const [domain_id, setDomainId] = useState("");
  const [edit_user_id, setEditUserId] = useState("");
  const [apiKey, setApiKey] = useState(uuidv4());
  const [isEdit, setIsEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSendEmailStatus, setIsSendEmailStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialogActionType, setDialogActionType] = useState("");

  // resend invitation Dialog Open
  const handleClickOpen = (userData, actionType) => {
    setSelectedUser(userData);
    setDialogActionType(actionType);
    setOpenDialog(true);
  };

  // resend invitation Dialog Close
  const handleClose = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleConfirmAction = () => {
    setOpenDialog(false);
    if (dialogActionType === "resend") {
      resendInvite(selectedUser.email); // Resend invitation
    } else if (dialogActionType === "delete") {
      deleteUser(selectedUser._id); // Handle user deletion
    }
    setSelectedUser(null);
  };

  const openModal = () => {
    setOpen(true);
    setIsEdit(false);
    setEmail("");
    setRoleId("");
    setDomainId("");
    setEditUserId("");
    setIsSendEmailStatus(false);
    setApiKey(uuidv4());
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const { order, orderBy, setOrder, setOrderBy } = useSorting({
    defaultOrder: SortOrder.asc,
    defaultOrderBy: "created_date",
  });
  const { page, perPage, setPage, setPerPage } = usePagination({
    defaultPage: 1,
    defaultPerPage: 10,
  });

  const {
    data: users,
    loading: usersLoading,
    total,
  } = useFetch({
    service: UserService,
    func: "getUsers",
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order,
      orderBy,
      page,
      perPage,
      domainId:
        actAsDomainIdMemoised ||
        (user.attributes["custom:role"] !== SUPER_ADMIN
          ? loggedInUser?.domain_id
          : ""),
    },
    dependency: [
      user?.signInUserSession?.accessToken?.jwtToken,
      order,
      orderBy,
      page,
      perPage,
      loggedInUser,
      added,
      actAsDomainIdMemoised,
    ],
    onSuccess: () => setAdded(false),
    skip:
      user.attributes["custom:role"] !== SUPER_ADMIN &&
      !loggedInUser.domain_id,
  });

  const { data: roles } = useFetch({
    service: RoleService,
    func: "getRoles",
    payload: user?.signInUserSession?.accessToken?.jwtToken,
    dependency: [user?.signInUserSession?.accessToken?.jwtToken],
  });

  const { data: domains } = useFetch({
    service: DomainService,
    func: "getDomains",
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order: "ASC",
      orderBy: "domain_name",
      page: 1,
      perPage: 1000,
      searchText: "",
      isList: true,
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken],
  });

  const inviteUser = useCallback(() => {
    setInviteUserLoading(true);
    if (!isEdit) {
      UserService.inviteUser(user?.signInUserSession?.accessToken?.jwtToken, {
        email,
        role_id,
        apiKey,
        enableOrderStatusEmail: isSendEmailStatus,
        domain_id:
          domain_id || actAsDomainIdMemoised || loggedInUser?.domain_id,
      }).then(() => {
        setAdded(true);
        setInviteUserLoading(false);
        closeModal();
      }).catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong", {
          icon: false,
        });
      });
    } else {
      const payload = { role_id, user_id: edit_user_id, enableOrderStatusEmail: isSendEmailStatus };

      if (apiKey) {
        payload["apiKey"] = apiKey;
      }

      UserService.editUser(
        user?.signInUserSession?.accessToken?.jwtToken,
        payload
      ).then(() => {
        setAdded(true);
        setInviteUserLoading(false);
        closeModal();
      }).catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong", {
          icon: false,
        });
        setInviteUserLoading(false);
      });
    }
  }, [
    isEdit,
    apiKey,
    edit_user_id,
    email,
    role_id,
    domain_id,
    isSendEmailStatus,
    user?.signInUserSession?.accessToken?.jwtToken,
    actAsDomainIdMemoised,
    JSON.stringify(loggedInUser),
  ]);

  const resendInvite = useCallback(
    (email) => {
      UserService.resendInviteToUser(
        user?.signInUserSession?.accessToken?.jwtToken,
        {
          email,
        }
      )
        .then(() => {
          toast.success("Invitation resent to User", { icon: false });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || "Something went wrong", {
            icon: false,
          });
        });
    },
    [
      user?.signInUserSession?.accessToken?.jwtToken,
      JSON.stringify(loggedInUser),
    ]
  );

  const deleteUser = useCallback(
    (userId) => {
      UserService.deleteUser(user?.signInUserSession?.accessToken?.jwtToken,userId)
        .then(() => {
          toast.success("User deleted successfully", { icon: false });
          setAdded(true);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || "Something went wrong", {
            icon: false,
          });
        });
    },
    [user?.signInUserSession?.accessToken?.jwtToken]
  );

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendEmailStatusChange = (e) => {
    setIsSendEmailStatus(e.target.checked);
  }

  const handleDomainChange = (e) => {
    setDomainId(e.target.value);
  };

  const handleRoleChange = (e) => {
    const selectedRoleId = e.target.value;
    const superadminRoleId = roles.find((role) => role.role_name === SUPER_ADMIN)?._id;

    setRoleId(selectedRoleId);

    // If changing from superadmin to another role, disable the enableOrderStatusEmail flag
    if (selectedRoleId !== superadminRoleId && isSendEmailStatus) {
      setIsSendEmailStatus(false);
    }
  };

  const handleEditIconClick = (userData) => {
    setOpen(true);
    setIsEdit(true);
    setEmail(userData.email);
    setRoleId(userData.role_id._id);
    setDomainId(userData.domain_id._id);
    setIsSendEmailStatus(userData.enableOrderStatusEmail);
    setEditUserId(userData._id);
    setApiKey("");
  };

  const regenerateKey = () => {
    setApiKey(uuidv4());
  };

  useEffect(() => {
    if (loggedInUser?.role_id?.role_name === SUPER_ADMIN && !isEdit) {
      const [emailName, emailDomain] = email.split("@");
      const domainFound = domains.find(
        (domain) => domain.domain_name === emailDomain
      );
      if (domainFound) {
        setDomainId(domainFound._id);
      }
    }
  }, [email, isEdit, JSON.stringify(domains), JSON.stringify(loggedInUser)]);

  useEffect(() => {
    setPage(1);
  }, [actAsDomainIdMemoised]);

  const modalTitle = useMemo(() => {
    return isEdit ? "Edit User" : "Invite User";
  }, [isEdit]);

  const modalContent = useMemo(() => {
    let tempRoles = roles,
      tempDomainId = "",
      dropdownValue = "",
      roleValue = "";

    if (loggedInUser?.role_id?.role_name === "admin" || actAsDomainIdMemoised) {
      tempRoles = roles.filter((role) => role.role_name !== SUPER_ADMIN);
    }

    if (
      (loggedInUser?.role_id?.role_name !== SUPER_ADMIN &&
        !loggedInUser?.domain?.isProxyDomain) ||
      actAsDomainIdMemoised
    ) {
      tempDomainId = actAsDomainIdMemoised || loggedInUser?.domain_id;
    }

    const selectedDomain = domains.find(
      (itemDomain) => itemDomain._id === (domain_id || tempDomainId)
    );
    if (selectedDomain) {
      const label = `${selectedDomain.domain_name} ${
        selectedDomain.carrier_alias ? `(${selectedDomain.carrier_alias})` : ""
      }`;

      dropdownValue = {
        value: selectedDomain ? selectedDomain._id : "",
        label: selectedDomain ? label : "",
      };
    }

    const selectedRole = tempRoles.find((itemRole) => itemRole._id === role_id);
    if (selectedRole) {
      roleValue = {
        value: selectedRole._id,
        label: selectedRole.role_display_name,
      };
    }

    let roleOptions = [];
    if (isEdit) {
      roleOptions = tempRoles
        .filter((item) => item.role_name !== SUPER_ADMIN)
        .map((item) => {
          return {
            value: item._id,
            label: item.role_display_name,
          };
        });
    } else {
      roleOptions = tempRoles.map((item) => {
        return {
          value: item._id,
          label: item.role_display_name,
        };
      });
    }

    return (
      <div className="add-domain">
        <div className="form-field-box">
          <label>
            API Key{" "}
            <span className="note">
              (API key won't be visible once it is created, so copy it before
              leaving the popup.)
            </span>
          </label>
          {isEdit ? (
            <div>
              <span>{apiKey}</span>
              <br />
              <button className="btn add-domain-btn" onClick={regenerateKey}>
                Regenerate API Key
              </button>
            </div>
          ) : (
            <span>{apiKey}</span>
          )}
        </div>
        <div className="form-field-box">
          <label>Email</label>
          <input
            value={email}
            onChange={handleEmailChange}
            disabled={isEdit}
            placeholder="Enter Email"
          />
          <label className="d-error">
            {!email.trim() && "Please Enter Email"}
          </label>
        </div>
        <div className="form-field-box">
          <label>Role</label>
          <Dropdown
            options={roleOptions}
            value={roleValue}
            onChange={(newValue) =>
              handleRoleChange({ target: { value: newValue.value } })
            }
          />
          <label className="d-error">{!role_id && "Please Select Role"}</label>
        </div>

        <div className="form-field-box">
          <label>Domain</label>
          <Dropdown
            options={domains.map((item) => {
              const label = `${item.domain_name} ${
                item.carrier_alias ? `(${item.carrier_alias})` : ""
              }`;
              return {
                value: item._id,
                label,
              };
            })}
            disabled={
              (loggedInUser?.role_id?.role_name !== SUPER_ADMIN &&
                !loggedInUser?.domain?.isProxyDomain) ||
              actAsDomainIdMemoised ||
              isEdit
            }
            onChange={(newValue) =>
              handleDomainChange({ target: { value: newValue.value } })
            }
            value={dropdownValue}
            searchable
          />
          <label className="d-error">
            {!domain_id && !tempDomainId && "Please Select Domain"}
          </label>
        </div>
        {loggedInUser?.role_id?.role_name === SUPER_ADMIN && roles.find((role) => role.role_name === SUPER_ADMIN)?._id === role_id && <div className="switch-box">
          <label className="complementory switch-label-text">Enable Order status email notifications</label>&nbsp;
          <Switch color="primary" size="medium" onChange={(e) => handleSendEmailStatusChange(e)} checked={isSendEmailStatus} />
        </div>}
      </div>
    );
  }, [
    isEdit,
    regenerateKey,
    roles,
    JSON.stringify(domains),
    email,
    role_id,
    isSendEmailStatus,
    domain_id,
    JSON.stringify(loggedInUser),
    inviteUserLoading,
    actAsDomainIdMemoised,
  ]);

  const btnDisabled = useMemo(() => {
    let tempDomainId = "";
    if (
      (loggedInUser?.role_id?.role_name !== SUPER_ADMIN &&
        !loggedInUser?.domain?.isProxyDomain) ||
      actAsDomainIdMemoised
    ) {
      tempDomainId = actAsDomainIdMemoised || loggedInUser?.domain_id;
    }

    if (!email.trim()) {
      return true;
    } else if (!role_id) {
      return true;
    } else if (inviteUserLoading) {
      return true;
    } else if (!tempDomainId && !domain_id) {
      return true;
    } else {
      return false;
    }
  }, [
    email,
    role_id,
    domain_id,
    actAsDomainIdMemoised,
    JSON.stringify(loggedInUser),
    inviteUserLoading,
  ]);

  const modalAction = useMemo(() => {
    let btnContent;

    if (inviteUserLoading) {
      btnContent = <Spinner size={22} color="white" />;
    } else if (isEdit) {
      btnContent = `Update User`;
    } else {
      btnContent = `Invite User`;
    }

    return (
      <div className="add-domain-btn-div">
        <button
          className={`btn add-domain-btn ${btnDisabled && "disabled"}`}
          disabled={btnDisabled}
          onClick={inviteUser}
        >
          {btnContent}
        </button>
      </div>
    );
  }, [isEdit, btnDisabled, inviteUserLoading, inviteUser]);

  const headCells = [
    {
      id: "email",
      accessor: "email",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Email",
      width: "20%",
    },
    {
      id: "role_display_name",
      accessor: "role_id.role_display_name",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Role",
      width: "15%",
    },
    {
      id: "domain_name",
      accessor: "domain_id.domain_name",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Domain",
      width: "20%",
    },
    {
      id: "created_date",
      accessor: "created_date",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Created Date",
      width: "20%",
    },
    {
      id: "last_activity_time",
      accessor: "last_activity_time",
      align: "left",
      disablePadding: false,
      sortEnabled: true,
      label: "Last Activity Time",
      width: "15%",
    },
    {
      id: "action",
      accessor: "action",
      align: "center",
      disablePadding: false,
      sortEnabled: false,
      label: "Action",
      width: "10%",
    },
  ];

  return (
    <div className="root">
      <div className="table-layout">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Users</h3>
          <button className="btn add-domain-btn" onClick={openModal}>
            Invite User
          </button>
        </div>
        <div className="users-table">
          <Table
            data={users}
            loading={usersLoading}
            total={total}
            perPage={perPage}
            page={page}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setPage={setPage}
            setPerPage={setPerPage}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={UserRow}
            rowProps={{
              handleEditIconClick,
              handleResendIconClick: (userData) => handleClickOpen(userData, 'resend'),
              handleDeleteIconClick: (userData) => handleClickOpen(userData, 'delete'),
              loggedInUser,
            }}
            noDataFoundMessage={`No Users Found`}
          />
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {dialogActionType === "resend" ? "Confirm Resend Invitation" : "Confirm Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogActionType === "resend"
                  ? "Are you sure you want to Re-send the invitation to this user?"
                  : "Are you sure you want to Delete this user?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button className="btn" onClick={handleConfirmAction} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <AddEditUser
        open={open}
        title={modalTitle}
        content={modalContent}
        action={modalAction}
        onClose={closeModal}
      />
    </div>
  );
};

export default ManageUsers;
