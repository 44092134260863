import React, { useState } from 'react';
import { Checkbox, TableRow } from '@mui/material';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import { dateFormat } from "../../utils/methods";
import _ from "lodash";
import { SUPER_ADMIN } from '../../utils/appConstants';

const Row = ({ item, headCells, loggedInUser, onCheckboxChange }) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    onCheckboxChange(item, checked); // Pass row data and checkbox value to the parent
  };
  return (
    <TableRow className="table-body-row">
      {
        headCells.map((headCell) => {

          if (headCell.id === 'fetch_supplier') {
            return loggedInUser?.role_id?.role_name !== SUPER_ADMIN ? null : (
              <td key={headCell.id} style={{ width: headCell.width, textAlign: headCell.align }}>
                <Checkbox checked={item?.is_fetch_supplier || checked} onChange={handleCheckboxChange} />
              </td>
            );
          }

          if (headCell.id === 'created_date') {
            const value = _.get(item, headCell.accessor);
            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={value ? dateFormat(new Date(value).toISOString()) : ''} />);
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={_.get(item, headCell.accessor)} />);
        })
      }
    </TableRow>
  );
};

export default Row;
