import axios from 'axios';
import { API_URL } from "./utils/appConstants";

const axiosInstance = axios.create({
  baseURL: API_URL,
  // baseURL: "http://localhost:3000",
  headers: {
    "Content-type": "application/json",
  },
});

// export const secondaryAxiosInstance = axios.create({
//   baseURL: SECONDARY_API_URL,
//   headers: {
//     "Content-type": "application/json",
//   },
// });

export default axiosInstance;
